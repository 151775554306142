import styled from "styled-components"

const MainContainer = styled.main`
  margin: 0 auto;
  overflow: hidden;
  min-height: calc(100vh - 210px);
  background-color: white;
`

export default MainContainer
