import styled from "styled-components"

const PostDate = styled.h2`
  color: #aaa;
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 1.2em;
  font-weight: normal;
`

export default PostDate
