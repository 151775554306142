import React from "react"
import Container from "./Container"

const Footer = () => {
  return (
    <footer>
      <Container>© {new Date().getFullYear()} Ben Harrison</Container>
    </footer>
  )
}

export default Footer
