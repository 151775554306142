import styled, { css } from "styled-components"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 1.45rem 1.0875rem;

  ${({ header }) =>
    header &&
    css`
      display: flex;
      align-items: center;
      padding: 0 1.0875rem;
      height: 105px;
    `}
`

export default Container
