import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Moment from "react-moment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostSection from "../components/posts/PostSection"
import PostDate from "../components/posts/PostDate"

import { Link } from "gatsby"
const shortcodes = { Link } // Provide common components here

export default function Template({ data: { mdx } }) {
  const { frontmatter } = mdx // data.markdownRemark holds our post data
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <PostSection>
        <h1>{frontmatter.title}</h1>
        <PostDate>
          <Moment format="MMMM DD, YYYY">{frontmatter.date}</Moment>
        </PostDate>
        <div className="blog-post-content">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </PostSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
      }
    }
  }
`
