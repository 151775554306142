import React from "react"
import PropTypes from "prop-types"
import MainContainer from "./MainContainer"
import Container from "./Container"

const Main = props => {
  const { children } = props
  return (
    <MainContainer>
      <Container>{children}</Container>
    </MainContainer>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

Main.defaultProps = {
  children: undefined,
}

export default Main
